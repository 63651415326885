import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'

import Content from './Content'

import styles from './styles.module.css'

export default function Gallery(props) {
  return (
    <div className={styles.container}>
      <Container>
        {props.title ? <h2 className={styles.title}>{props.title}</h2> : null}
        <Content {...props} />
      </Container>
    </div>
  )
}
