import React from 'react'
import InteligentLink from '@components/InteligentLink'
import getFileURL from '@helpers/misc/getFileURL'
import useComponentWidth from '@hooks/useComponentWidth'
import useRef from '@hooks/useRef'
import useWidth from '@hooks/useWidth'

import Image from './Image'

import styles from './styles.module.css'

export default function GalleryContent(props) {
  const {items} = props
  const screenWidth = useWidth()
  const {cols} = screenWidth <= 760 ? {cols: props.colsMobile} : props
  const container = useRef()
  const containerWidth = useComponentWidth(container)
  const width = containerWidth / cols

  if (!items.length) return null

  return (
    <div className={styles.posts} ref={container}>
      {items.map((item, index) => (
        <InteligentLink
          className={styles.link}
          key={index}
          style={{width}}
          to={item.url || getFileURL(item.image)}>
          <Image item={item} width={width - 20} />
          {item.title && <div className={styles.itemTitle}>{item.title}</div>}
          {item.description && <div className={styles.description}>{item.description}</div>}
        </InteligentLink>
      ))}
    </div>
  )
}
