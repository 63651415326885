import React from 'react'
import roundToNearest from '@helpers/misc/roundToNearest'
import useResizedImageUrlSsr from '@hooks/useResizedImageUrlSsr.main'

import styles from './styles.module.css'

export default function GalleryImage(props) {
  const {item, width} = props

  const url = useResizedImageUrlSsr({
    fileId: item.image._id,
    width: roundToNearest(width * 2, 200) || 400,
    height: roundToNearest(width * 2, 200) || 400
  })

  return (
    <img
      suppressHydrationWarning
      src={url}
      alt={item.title || item.description}
      title={item.title || item.description}
      className={styles.image}
    />
  )
}
