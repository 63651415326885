/**
 *
 * WARNING
 * this function will return an s3 file.
 * use a URL that goes through CloudFront (CDN) if possible.
 */

export default function GetFileURL(file) {
  if (!file) return
  return `https://s3.amazonaws.com/${file.bucket}/${file.key.replace(/ /g, '%20')}`;
}
